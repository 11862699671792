(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/oddset/client-error-handler/assets/javascripts/error-dialogs.js') >= 0) return;  svs.modules.push('/components/oddset/client-error-handler/assets/javascripts/error-dialogs.js');
"use strict";


const {
  account_balance: accountBalanceComponent
} = svs.components;
const {
  Currency: currency,
  CurrencyFromJupiterString: currencyFromJupiterString
} = svs.utils.format;
const {
  dialog
} = svs.components;

const getAccountBalance = () => new Promise(resolve => {
  accountBalanceComponent.get(true, balance => {
    resolve(currencyFromJupiterString(balance));
  });
});

const displayGenericErrorDialog = callback => {
  const instance = new dialog.Confirm({
    type: dialog.type.ERROR,
    title: 'Något gick fel',
    message: [{
      text: 'Något gick fel vid betalningen, försök igen om en liten stund.',
      type: dialog.message.TEXT
    }],
    actions: [{
      title: 'Ok',
      callback: () => {
        if (callback) {
          callback();
        }
        instance.close();
      }
    }]
  });
  dialog.api.add(instance);
};

const onInsufficientFunds = async callback => {
  const balance = await getAccountBalance();
  const instance = new dialog.Confirm({
    type: dialog.type.DEFAULT,
    branding: svs.ui.dialog.branding.SPORT,
    icon: 'money',
    title: 'Du har inte tillräckligt med pengar på ditt spelkonto för att betala ditt spel.',
    message: [{
      text: "Saldo: ".concat(currency(balance), " kr"),
      type: dialog.message.TEXT
    }],
    actions: [{
      title: 'Avbryt',
      callback: () => {
        if (callback) {
          callback();
        }
        instance.close();
      }
    }, {
      title: 'Sätt in pengar',
      callback: () => {
        const returnUrl = svs.utils.url.makeInternal(svs.utils.url.getLocation().pathname);
        svs.utils.url.navigate("".concat(svs.core.urlMapping.get('transactionsHome'), "?returnUrl=").concat(returnUrl));
        if (callback) {
          callback();
        }
      }
    }]
  });
  dialog.api.add(instance);
};

const onOddsChanged = callback => {
  const instance = new dialog.Confirm({
    type: dialog.type.ERROR,
    title: 'Oddsändring',
    message: [{
      text: 'Odds har ändrats för ett eller flera spel. Kontrollera din kupong.',
      type: dialog.message.TEXT
    }],
    actions: [{
      title: 'Ok',
      callback: () => {
        if (callback) {
          callback();
        }
        instance.close();
      }
    }]
  });
  dialog.api.add(instance);
};

const onLimitExceeded = async callback => {
  const limits = await svs.accountServices.player_timelimits.getPlayerTimeLimits();
  if ((limits === null || limits === void 0 ? void 0 : limits.day.remaining) === '0' || (limits === null || limits === void 0 ? void 0 : limits.week.remaining) === '0' || (limits === null || limits === void 0 ? void 0 : limits.month.remaining) === '0') {
    const instance = new dialog.Confirm({
      type: dialog.type.DEFAULT,
      icon: 'time-limit',
      branding: svs.ui.dialog.branding.SPORT,
      title: 'Du har nått din tidsgräns',
      message: [{
        text: 'När du har nått din tidsgräns kan du inte betala för spel på Sport och Casino.',
        type: dialog.message.TEXT
      }],
      actions: [{
        title: 'Stäng',
        callback: () => {
          if (callback) {
            callback();
          }
          instance.close();
        }
      }, {
        title: 'Till mina gränser',
        callback: () => {
          svs.utils.url.navigate(svs.core.urlMapping.get('myProfileLimits'));
          if (callback) {
            callback();
          }
        }
      }]
    });
    dialog.api.add(instance);
  } else {
    displayGenericErrorDialog(callback);
  }
};

const checkRestricitions = async callback => {
  const canPlay = await svs.accountservices.player_exclusions.canPlayOnVertical('oddset', callback);

  if (canPlay) {
    displayGenericErrorDialog(callback);
  }
};
setGlobal('svs.components.oddset.clientErrorHandler.errorDialogs', {
  checkRestricitions,
  displayGenericErrorDialog,
  onInsufficientFunds,
  onLimitExceeded,
  onOddsChanged
});

 })(window);