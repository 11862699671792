(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/oddset/client-error-handler/assets/javascripts/payment.js') >= 0) return;  svs.modules.push('/components/oddset/client-error-handler/assets/javascripts/payment.js');
"use strict";


const {
  ErrorType,
  ErrorReason,
  getTypeReasonFromData
} = svs.components.oddset.common.clientErrors;
const {
  checkRestricitions,
  displayGenericErrorDialog,
  onInsufficientFunds,
  onLimitExceeded,
  onOddsChanged
} = svs.components.oddset.clientErrorHandler.errorDialogs;
const logger = svs.core.log.getLogger('components:oddset:client-error-handler:payment');

const handlePaymentError = (type, reason) => {
  switch (type) {
    case ErrorType.KAMBI_COUPON:
      switch (reason) {
        case ErrorReason[ErrorType.KAMBI_COUPON].GENERAL:
        case ErrorReason[ErrorType.KAMBI_COUPON].ACCOUNT_BLOCKED:
          checkRestricitions();
          break;
        case ErrorReason[ErrorType.KAMBI_COUPON].LIMIT_EXCEEDED:
          onLimitExceeded();
          break;
        case ErrorReason[ErrorType.KAMBI_COUPON].INSUFFICIENT_FUNDS:
          onInsufficientFunds();
          break;
        default:
          displayGenericErrorDialog();
          break;
      }
      break;
    case ErrorType.KAMBI_BET:
    case ErrorType.KAMBI_SELECTION:
      switch (reason) {
        case ErrorReason[ErrorType.KAMBI_SELECTION].ODDS_CHANGED:
          onOddsChanged();
          break;
        default:
          displayGenericErrorDialog();
          break;
      }
      break;
    default:
      displayGenericErrorDialog();
      break;
  }
};

const onPaymentError = errorDatas => {
  logger.warn('Handling error(s)', JSON.stringify(errorDatas));
  if (!(errorDatas !== null && errorDatas !== void 0 && errorDatas.errors) || !Array.isArray(errorDatas.errors)) {
    handlePaymentError(undefined, undefined); 
    return;
  }
  const error = getTypeReasonFromData(errorDatas.errors[0]);
  handlePaymentError(error.type, error.reason);
};
setGlobal('svs.components.oddset.clientErrorHandler.payment', {
  onPaymentError
});

 })(window);